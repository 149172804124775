/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

import { User } from "@firebase/auth-types";
import firebase from "firebase/app";
import "firebase/auth";
import { TApplication } from "interfaces";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";

import { TSelfEmployedBusinessType } from "pages/suitability-stage-two/suitability-form/types";
import {
    bootIntercomForAuthenticatedUsers,
    bootIntercomForUnAuthenticatedUsers,
    shutdownIntercom,
} from "utils/analytics";
import { convertApplicationStatusResponse, convertProfileResponse } from "utils/application-helpers";
import { convertObjectKeysToCamelCase, getIsWayhomeUser, getLDSecondaryKey } from "utils/helpers";

import { useApi } from "./use-api";

type TSignUpFn = (userData: {
    email: string;
    password: string;
    [key: string]: any;
}) => Promise<{ ok: boolean; status: number; body: any }>;
type TSignInFn = (email: string, password: string) => Promise<IAuthUser | null>;
type TSignOutFn = () => Promise<void>;
type TRequestPasswordResetFn = (email: string) => Promise<void>;
type TVerifyPasswordResetCodeFn = (code: string) => Promise<string>;
type TSetNewPasswordFn = (code: string, newPassword: string) => Promise<void>;
type TSetUserProfileDataFn = (userData: Partial<IUserProfile>) => void;
type TSetUserApplicationDataFn = (applicationData: IApplicationStatus) => void;
type TSetUserApplicationV2DataFn = (applicationData: TApplication) => void;

export interface IAuthUser {
    uuid: string | null;
    fbUser: User | null;
    application: IApplicationStatus | null;
    profile: Partial<IUserProfile> | null;
    applicationV2: TApplication | null;
}

export interface IApplicationStatus {
    applicationId: string;
    customerId: string;
    primaryApplicantId: string;
    isSuitable: boolean;
    canGoOnViewings: boolean;
    creditDecision?: string;
    hasPropertyUnderOffer: boolean;
    removeCustomerFromViewings: boolean | null;
    applicants: IApplicant[];
    waitListStatus?: TWaitListStatus;
}

export type ICreditStatus =
    | "not-started"
    | "submitted"
    | "processing"
    | "flag"
    | "verify"
    | "pass"
    | "fail"
    | "requested";

export interface IApplicant {
    firstName: string;
    lastName: string;
    applicantId: string;
    creditStatus: ICreditStatus;
    affordabilityCheckStatus: string;
    affordabilityCheckUrl: string | undefined;
}

export interface ISignUpReason {
    stillConsideringMortgage: boolean;
    age: boolean;
    creditScore: boolean;
    other: string;
    shariah: boolean;
    selfEmployed: boolean;
    traditionalFinancingUnavailable: boolean;
    otherAlternativesUnavailable: boolean;
}

export type THomeOccupants =
    | ""
    | "Just me"
    | "Me and my partner"
    | "Me, my partner and kid(s)"
    | "Me and my kid(s)"
    | "Other"
    | "Me and someone else"
    | "Single application"
    | "Joint application";

export type TJobStatus = "" | "Employed full-time" | "Employed part-time" | "Self-employment" | "Unemployment";

export type TWaitListStatus =
    | "qualified"
    | "not_qualified"
    | "can_do_affordability"
    | "priority_waitlist"
    | "first_fund"
    | null;

export interface IUserProfile {
    application: {
        signUpReason: ISignUpReason;
        maxRentAfterCredit?: number | null;
        aipIssuedAt: string | null;
        aipExpiryDate: string | null;
        waitListStatus: TWaitListStatus;
    };
    applicants: IApplicantData[];
    mainIncome: number;
    applicant2Income: number;
    deposit: number;
    desiredArea?: string;
    searchesId?: string;
    latitude?: number | string;
    longitude?: number | string;
    incomeVerified?: boolean;
    signUpReason: ISignUpReason;
    currentDependantsPreschool?: number;
    currentDependantsPrimary?: number;
    currentDependantsSecondary?: number;
    currentDependantsSixteenPlus?: number;
    maxRentAfterAffordability?: number;
    maxHomePrice?: number;
    phone?: string;
    // expenditureInformationGathered is used to flag whether user has completed 2nd part of form
    expenditureInformationGathered?: boolean;
    consentToProceed: boolean;
}

export interface IApplicantData {
    applicantID?: string;
    isLeadApplicant?: boolean;
    jobStatus: TJobStatus;
    jobEmployer?: string;
    jobIndustry?: string;
    jobSector?: string;
    jobTitle?: string;
    selfEmployedBusinessType?: TSelfEmployedBusinessType;
    selfEmployedExpensesSubmitted?: boolean;
    dateOfBirth?: string;
    email?: string;
    phone?: string;
}

export interface IApplicantProfileV2 {
    id: string;
    phone: string | null;
    email: string | null;
    date_of_birth: string;
    is_lead_applicant: boolean;

    job_sector?: string;
    job_industry?: string;
    job_title?: string;
    job_employer?: string;
    job_status?: TJobStatus;
    job_free_text?: string | null;
    job_time_in_role: string | null;

    business_affected_by_covid: string | null;
    does_receive_benefits: boolean | null;
    self_employed_business_type: TSelfEmployedBusinessType;
    self_employed_earnings_2019_2020: string | null;
    self_employed_earnings_2020_2021: string | null;
    has_self_employed_expenses: boolean;
}

export interface IApplicationProfileV2 {
    max_rent_after_credit: number | null;
    rental_duration?: string;
    sign_up_reason: ISignUpReason;
    total_rent?: number | null;
    consent_to_proceed?: boolean;
    aip_issued_at: string | null;
    aip_expiry_date: string | null;
    waitlist_status: TWaitListStatus;
}

export interface IUserProfileV2 {
    phone: string;
    home_occupants: THomeOccupants;
    current_deposit: number;
    current_applicant1_income: number;
    current_applicant2_income: number;
    current_dependants_preschool: number;
    current_dependants_primary: number;
    current_dependants_secondary: number;
    current_dependants_sixteen_plus: number;
    latitude: number;
    longitude: number;
    area: string;
    applicants: IApplicantProfileV2[];
    application: IApplicationProfileV2;
    income_verified: boolean;
    max_home_price?: number;
    max_rent_after_affordability?: number;
    maximum_comfortable_rent: number | null;
    current_partner1_income: number | null;
    current_partner2_income: number | null;
    expenditure_information_gathered: boolean;
}

export interface IAuthContextState {
    user: IAuthUser;
    isAuthLoading: boolean;
    isProfileLoading: boolean;
    isAuthInitialising: boolean;
    isLoadingProfileDependency?: boolean;
    error: string | null;
    signIn: TSignInFn;
    signUp: TSignUpFn;
    signOut: TSignOutFn;
    requestPasswordReset: TRequestPasswordResetFn;
    verifyPasswordResetCode: TVerifyPasswordResetCodeFn;
    setNewPassword: TSetNewPasswordFn;
    setUserProfileData: TSetUserProfileDataFn;
    setUserApplicationData: TSetUserApplicationDataFn;
    setUserApplicationV2Data: TSetUserApplicationV2DataFn;
}

export interface ApplicationACStatus {
    isACCompleteForAllApplicants: boolean;
    isACCompleteForPrimaryApplicant: boolean;
    isACCompleteForSecondaryApplicant: boolean | null;
    showCta?: boolean | null;
    primaryApplicantId?: string | null;
}

const AuthContext = createContext<IAuthContextState | undefined>(undefined);

export const AuthProvider: React.FC = ({ children }) => {
    const { error, get, post } = useApi();
    const client = useLDClient();
    const [user, setUser] = useState<IAuthUser>({
        uuid: null,
        fbUser: null,
        application: null,
        profile: null,
        applicationV2: null,
    });
    const [isAuthInitialising, setIsAuthInitialising] = useState<boolean>(true);
    const [isAuthLoading, setIsAuthLoading] = useState<boolean>(false);
    const [isProfileLoading, setIsProfileLoading] = useState<boolean>(false);
    const [isLoadingProfileDependency, setIsLoadingProfileDependency] = useState<boolean>(true);
    const { uuid, fbUser } = user;
    const { applicationsMigration } = useFlags();
    const [migrationFlag, setMigrationFlag] = useState<boolean>();

    useEffect(() => {
        return firebase.auth().onAuthStateChanged(async (newUserObj) => {
            if (newUserObj?.email?.includes("wayhome")) {
                /* eslint-disable */
                client?.identify({ key: "wayhome", secondary: getLDSecondaryKey() });
            }

            const isWayhomeUser = getIsWayhomeUser(newUserObj?.email);
            const flag = isWayhomeUser || applicationsMigration;

            setMigrationFlag(flag);

            const currentUser = await firebaseUserToWayhomeUser(newUserObj);
            setUser(currentUser);
            setIsAuthInitialising(false);
            if (!currentUser.uuid) setIsLoadingProfileDependency(false);
        });
    }, [applicationsMigration]);

    const initializeData = useCallback(
        async (token: string) => {
            if (migrationFlag === undefined) {
                return {};
            }

            if (migrationFlag) {
                const applicationResponseV2 = await get("/applications/v3/customer/application", token);

                return {
                    applicationResponseV2,
                };
            }

            const profileData = await get("/v2/profile", token);
            const applicationStatusData = await get("/v1/application-status", token);
            const searchesDataResponse = await get("/v0/searches", token);

            return {
                profileData,
                applicationStatusData,
                searchesDataResponse,
            };
        },
        [migrationFlag, get],
    );

    useEffect(() => {
        (async () => {
            if (uuid !== null && fbUser !== null) {
                setIsLoadingProfileDependency(true);
                setIsProfileLoading(true);

                const token = await fbUser.getIdToken();
                const { applicationResponseV2, profileData, applicationStatusData, searchesDataResponse } =
                    await initializeData(token);

                setIsProfileLoading(false);

                const applicationV2 = applicationResponseV2?.ok
                    ? convertObjectKeysToCamelCase(applicationResponseV2.body)
                    : null;

                const hasProfileApplicationSearchesData =
                    profileData?.body && applicationStatusData?.body && searchesDataResponse?.body?.length;

                const searchesData = hasProfileApplicationSearchesData
                    ? {
                          areaName: searchesDataResponse.body[0]?.area_name,
                          searchesId: searchesDataResponse.body[0]?.id,
                      }
                    : undefined;

                const profile = profileData?.body ? convertProfileResponse(profileData.body, searchesData) : null;
                const application = applicationStatusData?.body
                    ? convertApplicationStatusResponse(applicationStatusData.body)
                    : null;

                setUser({
                    uuid: uuid || applicationV2?.id,
                    fbUser,
                    application: application
                        ? {
                              ...application,
                              waitListStatus: profile?.application.waitListStatus,
                          }
                        : null,
                    applicationV2,
                    profile,
                });

                setIsLoadingProfileDependency(false);
            }
        })();
    }, [uuid, fbUser, initializeData]);

    const firebaseUserToWayhomeUser = async (firebaseUser: User | null): Promise<IAuthUser> => {
        let uuid = null,
            fbUser = null;
        if (firebaseUser !== null) {
            const tokenResults = await firebaseUser.getIdTokenResult();
            uuid = tokenResults.claims.wayhome_id;
            fbUser = firebaseUser;

            const intercomHash = tokenResults?.claims?.intercom_hash as string;

            if (uuid && intercomHash) {
                /**
                 * Identify an authenticated user on intercom
                 */
                bootIntercomForAuthenticatedUsers(uuid, intercomHash);
            }
        }
        return { uuid, fbUser, profile: null, application: null, applicationV2: null };
    };

    const signUp: TSignUpFn = async (userData) => {
        setIsAuthLoading(true);
        const response = await post("/v2/sign-ups", userData);
        setIsAuthLoading(false);
        return response;
    };

    const getFirebaseUser = async (email: string, password: string) => {
        if (email.includes("wayhome-become-token")) {
            return await firebase.auth().signInWithCustomToken(password);
        }

        return await firebase.auth().signInWithEmailAndPassword(email, password);
    };

    const signIn: TSignInFn = async (email, password) => {
        try {
            setIsAuthLoading(true);
            const firebaseUser = await getFirebaseUser(email, password);
            setIsAuthLoading(false);

            if (email.includes("wayhome")) {
                /* eslint-disable */
                client?.identify({ key: "wayhome", secondary: getLDSecondaryKey() });
            }

            return firebaseUserToWayhomeUser(firebaseUser.user);
        } catch (error) {
            setIsAuthLoading(false);
            return null;
        }
    };

    const signOut: TSignOutFn = async () => {
        setIsAuthLoading(true);
        await firebase.auth().signOut();
        setIsAuthLoading(false);

        shutdownIntercom();
        bootIntercomForUnAuthenticatedUsers();
    };

    const requestPasswordReset: TRequestPasswordResetFn = async (email) => {
        setIsAuthLoading(true);
        await post("/v1/password-reset", { email });
        setIsAuthLoading(false);
    };

    const verifyPasswordResetCode: TVerifyPasswordResetCodeFn = async (code) => {
        try {
            setIsAuthLoading(true);
            const results = await firebase.auth().verifyPasswordResetCode(code);
            setIsAuthLoading(false);
            return results;
        } catch (error) {
            setIsAuthLoading(false);
            return Promise.reject({ code: "invalid-code" });
        }
    };

    const setNewPassword: TSetNewPasswordFn = async (code, newPassword) => {
        setIsAuthLoading(true);
        const result = await firebase.auth().confirmPasswordReset(code, newPassword);
        setIsAuthLoading(false);
        return result;
    };

    const setUserProfileData: TSetUserProfileDataFn = async (userData) => {
        setUser({ ...user, profile: { ...user.profile, ...userData } });
    };

    const setUserApplicationData: TSetUserApplicationDataFn = async (applicationData) => {
        setUser({ ...user, application: { ...user.application, ...applicationData } });
    };

    const setUserApplicationV2Data: TSetUserApplicationV2DataFn = async (applicationData) => {
        setUser((prevState) => ({ ...prevState, applicationV2: { ...prevState.applicationV2, ...applicationData } }));
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                error,
                isAuthLoading,
                isProfileLoading,
                isAuthInitialising,
                isLoadingProfileDependency,
                signUp,
                signIn,
                signOut,
                requestPasswordReset,
                verifyPasswordResetCode,
                setNewPassword,
                setUserProfileData,
                setUserApplicationData,
                setUserApplicationV2Data,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error("useAuthContext must be used within an AuthProvider");
    }

    return context;
};
