import { useFlags } from "launchdarkly-react-client-sdk";

import { routePaths } from "config/route-paths";

export const useSignupLink = () => {
    const { applicationsMigration } = useFlags();

    const signupLink = applicationsMigration ? routePaths.marketing.signup.path : routePaths.product.suitability.path;

    return {
        signupLink,
        oldSignUpLink: routePaths.product.suitability.path,
        newSignUpLink: routePaths.marketing.signup.path,
    };
};
