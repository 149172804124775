import React from "react";

import { VerticalSpacing } from "@wayhome-uk/design-system";
import { PageWidth } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth, useSignupLink } from "hooks";
import styled from "styled-components/macro";

import IconFloral from "components/media/svg/icon-floral-double.svg";
import { NewHeader2, NewLongParagraph } from "config/theme";

import { SecondaryButton, SecondaryLink } from "../../../components/buttons-and-links";

export const JoinWaitlist: React.FC<IBaseComponent> = ({ className }) => {
    const { user } = useAuth();
    const { signupLink } = useSignupLink();

    return (
        <Wrapper className={className}>
            <StyledPageWidth>
                <Title>Buying with Wayhome</Title>

                <StyledSection>
                    <Paragraph>
                        Due to overwhelming demand, we have now closed applications to our first fund.
                    </Paragraph>
                    <VerticalSpacing size={4} />
                    <Paragraph>
                        We&apos;ve already helped hundreds of people achieve their dream of homeownership, and the good
                        news is that you could join them.
                    </Paragraph>

                    <VerticalSpacing size={16} />
                    {!user.uuid ? (
                        <BoldParagraph>Sign up today to join the waitlist for our second fund.</BoldParagraph>
                    ) : (
                        <BoldParagraph>Great news, you&apos;re already on our waitlist.</BoldParagraph>
                    )}
                </StyledSection>

                <VerticalSpacing size={16} />
                {!user.uuid ? (
                    <SecondaryButton>
                        <SecondaryLink data-test-id="cta-next-step" as="a" href={signupLink}>
                            Sign Up
                        </SecondaryLink>
                    </SecondaryButton>
                ) : null}
            </StyledPageWidth>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.neutral100};
    display: flex;
`;

const StyledPageWidth = styled(PageWidth)`
    padding: ${({ theme }) => theme.spacing40};

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        background: url(${IconFloral}) 95% 100% no-repeat;
        background-color: ${({ theme }) => theme.neutral100};
        padding: ${({ theme }) => theme.spacing48};
    }
`;

const Title = styled.h2`
    ${NewHeader2};
    margin: 0 auto ${({ theme }) => theme.spacing48};
    font-weight: 500;
    text-align: left;
    max-width: none;
`;

const StyledSection = styled.section`
    max-width: 695px;
    @media (min-width: 1460px) {
        max-width: none;
    }
`;

export const Paragraph = styled.p`
    ${NewLongParagraph};
`;

export const BoldParagraph = styled.p`
    ${NewLongParagraph};
    font-weight: bold;
`;
