import React from "react";

import { PageWidth } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth, useSignupLink } from "hooks";
import styled from "styled-components/macro";

import { PrimaryButton, PrimaryLink } from "components/buttons-and-links";
import { ExplainerVideo } from "components/explainer-video";
import { NewHeader1, NewLongParagraph } from "config/theme";

export const HeroHome = ({ className }: IBaseComponent) => {
    const { user } = useAuth();
    const { signupLink } = useSignupLink();

    return (
        <div className={className}>
            <PageWidth>
                <HomeWrapper>
                    <TextAndButtonWrapper>
                        <StyledHeading>Home buying help when a mortgage doesn&apos;t work</StyledHeading>
                        <StyledParagraph>
                            The debt-free way to buy the same type of home you can afford to rent.
                        </StyledParagraph>
                        {!user.uuid ? (
                            <PrimaryButton>
                                <PrimaryLink as="a" href={signupLink}>
                                    Sign Up
                                </PrimaryLink>
                            </PrimaryButton>
                        ) : null}
                    </TextAndButtonWrapper>
                    <ExplainerVideo className="explainer-video" />
                </HomeWrapper>
            </PageWidth>
        </div>
    );
};

const HomeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${({ theme }) => `${theme.spacing24} 0 ${theme.spacing40}`};
    align-items: center;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        flex-direction: row;
        margin: 80px 0;

        > * {
            flex: 1;
        }
    }
`;

const TextAndButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.spacing24};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) and (max-width: ${({ theme }) => theme.mediumMaxWidth}) {
        margin-right: ${({ theme }) => theme.spacing16};
        margin-bottom: 0;
    }

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        margin-right: ${({ theme }) => theme.spacing24};
        margin-bottom: 0;
    }
`;

const StyledHeading = styled.h1`
    ${NewHeader1};
    font-weight: 550;
    margin-bottom: ${({ theme }) => theme.spacing24};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) and (max-width: ${({ theme }) => theme.mediumMaxWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing32};
    }
`;

const StyledParagraph = styled.p`
    ${NewLongParagraph};
    font-weight: 500;
    margin-bottom: ${({ theme }) => theme.spacing24};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) and (max-width: ${({ theme }) => theme.mediumMaxWidth}) {
        margin-bottom: ${({ theme }) => theme.spacing32};
    }
`;
