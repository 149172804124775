const filterWhiteList = "(liked)";
export const uuidRegexString = "([0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})";

export const routes = {
    marketing: {
        home: {
            path: "/",
            sitemap: true,
        },
        about: {
            path: "/about",
            sitemap: true,
        },
        howItWorks: {
            path: "/how-it-works",
            sitemap: true,
        },
        applicationProcess: {
            path: "/application-process",
            sitemap: true,
        },
        faqs: {
            path: "/faqs",
            sitemap: true,
        },
        blog: {
            path: "/blog",
            sitemap: true,
        },
        whereWeBuy: {
            path: "/where-we-buy",
            sitemap: true,
        },
        joinTheWaitList: {
            path: "/join-the-wait-list",
            sitemap: false,
        },
        intermediaries: {
            path: "/intermediaries",
            sitemap: false,
        },
        testimonials: {
            path: "/testimonials",
            sitemap: false,
        },
        login: {
            path: "/login",
            sitemap: false,
        },
        signup: {
            path: "/cx/sign-up",
            sitemap: false,
        },
    },
    product: {
        suitability: {
            path: "/suitability",
            sitemap: true,
        },
        suitabilityStageTwo: {
            path: "/suitability-stage-2",
            sitemap: false,
        },
        selfEmployedExpenses: {
            path: "/self-employed-expenses",
            sitemap: false,
        },
        selfEmployedExpensesIntro: {
            path: "/self-employed-expenses-intro",
            sitemap: false,
        },
        beforeWeBegin: {
            path: "/before-we-begin",
            sitemap: false,
        },
        tellUsAboutTheProperty: {
            path: "/tell-us-about-the-property",
            sitemap: false,
        },
        suitabilityMatches: {
            path: `/suitability/matches/:uuid${uuidRegexString}`,
            sitemap: false,
        },
        filteredSuitabilityMatches: {
            path: `/suitability/matches/:uuid${uuidRegexString}/filter/:filterBy${filterWhiteList}`,
            sitemap: false,
        },
        propertyDetail: {
            path: `/suitability/matches/:uuid${uuidRegexString}/listing/:listingID${uuidRegexString}`,
            sitemap: false,
        },
        makeAnOffer: {
            path: `/suitability/matches/:uuid${uuidRegexString}/listing/:listingID${uuidRegexString}/make-an-offer`,
            sitemap: false,
        },
        offerConfirmation: {
            path: `/suitability/matches/:uuid${uuidRegexString}/listing/:listingID${uuidRegexString}/offer-confirmed`,
            sitemap: false,
        },
        offPlatformOfferConfirmation: {
            path: `/suitability/matches/:uuid${uuidRegexString}/offer-confirmed`,
            sitemap: false,
        },
        startCreditCheck: {
            path: `/suitability/matches/:uuid${uuidRegexString}/listing/:listingID${uuidRegexString}/start-credit-check`,
            sitemap: false,
        },
        getApproved: {
            path: `/get-approved`,
            sitemap: false,
        },
        creditCheck: {
            root: "credit-check",
            path: `/credit-check/:customerID${uuidRegexString}?`,
            sitemap: false,
        },
        creditCheckAddApplicant2Details: {
            path: `/credit-check/:customerID${uuidRegexString}/add-applicant-2-details`,
            sitemap: false,
        },
        creditCheckThankYou: {
            path: `/credit-check/:customerID${uuidRegexString}/thank-you`,
            sitemap: false,
        },
        passwordReset: {
            path: "/password-reset",
            sitemap: false,
        },
        accountSettings: {
            path: "/account-settings",
            sitemap: false,
        },
        estimateCosts: {
            root: "estimate-costs",
            path: `/estimate-costs/:listingID${uuidRegexString}`,
            sitemap: false,
        },
        requestViewingSuccess: {
            path: "/request-viewing",
            sitemap: false,
        },
        homesNotFound: {
            path: "/homes-not-found",
            sitemap: false,
        },
        cannotHelpRightNow: {
            path: "/cannot-help-right-now",
            sitemap: false,
        },
        youAreEligible: {
            path: "/you-are-eligible",
            sitemap: false,
        },
        youAreOnTheWaitlist: {
            path: "/you-are-on-the-waitlist",
            sitemap: false,
        },
        bookAViewing: {
            path: `/suitability/matches/:uuid${uuidRegexString}/listing/:listingID${uuidRegexString}/book-a-viewing`,
            sitemap: false,
        },
        wayhomeOffer: {
            path: "/wayhome-offer",
            sitemap: false,
        },
        agreementInPrinciple: {
            path: "/agreement-in-principle",
            sitemap: false,
        },
    },
    misc: {
        keyConsiderations: {
            path: "/key-considerations",
            sitemap: true,
        },
        privacyPolicy: {
            path: "/privacy-policy",
            sitemap: true,
        },
        termsConditions: {
            path: "/terms-conditions",
            sitemap: true,
        },
        press: {
            path: "/press",
            sitemap: true,
        },
        explainer: {
            path: "/explainer",
            sitemap: true,
        },
        regulatoryInformation: {
            path: "/regulatory-information",
            sitemap: true,
        },
        cookiePolicy: {
            path: "/cookie-policy",
            sitemap: true,
        },
    },
};
