import React, { ComponentType, useEffect } from "react";

import { LoadingIndicator } from "@wayhome-uk/design-system";
import { useFlags } from "launchdarkly-react-client-sdk";
import styled from "styled-components";

import { useAuth } from "hooks/use-auth";
import { getIsWayhomeUser, queryStringToObject, redirectToResubmitApplication } from "utils/helpers";

export const withSignUpRedirect =
    <WrappedComponentProps extends object>(WrappedComponent: ComponentType<unknown>) =>
    (props: WrappedComponentProps) => {
        const { applicationsMigration } = useFlags();

        const { user, isLoadingProfileDependency } = useAuth();

        const { returnEmail } = queryStringToObject(window.location.search);

        const isAuthenticated = Boolean(user.fbUser);
        const resubmitApplication = user.applicationV2?.submitted === false;
        const noApplicationData = isAuthenticated && !user.applicationV2;
        const showSpinner = resubmitApplication || returnEmail || isLoadingProfileDependency || noApplicationData;

        const isWayhomeUser = getIsWayhomeUser(user.fbUser?.email) || applicationsMigration;

        useEffect(() => {
            const isLocalHost = window.location.host.includes("localhost");
            const signUpPage = window.location.pathname.includes("sign-up");
            /**
             * We get into an infinite reload loop on localhost
             * because of `window.location.replace(...)` that
             * refreshes the browser to properly link to the new `CX`
             */
            if (isLocalHost && signUpPage) {
                return;
            }

            if (resubmitApplication || returnEmail) {
                redirectToResubmitApplication();
            }
        }, [resubmitApplication, returnEmail]);

        if (!isWayhomeUser) {
            return <WrappedComponent {...props} />;
        }

        return showSpinner ? <PageLoader /> : <WrappedComponent {...props} />;
    };

const PageLoader = styled(LoadingIndicator)`
    padding-block: 200px;
`;
