import React from "react";

import { useSignupLink } from "hooks";

import { SecondaryButton, SecondaryLink } from "components/buttons-and-links";

export const CtaButton = () => {
    const { signupLink } = useSignupLink();

    return (
        <SecondaryButton>
            <SecondaryLink data-test-id="cta-next-step" as="a" href={signupLink}>
                Sign Up
            </SecondaryLink>
        </SecondaryButton>
    );
};
